/* App.css */
:root {
  --primary-color: #007bff;
  --secondary-color: #28a745;
  --background-gradient: linear-gradient(to bottom right, #d4f4e2, #a3d8ff); /* Updated gradient: soft green to soft blue */
  --text-color: #343a40;
  --card-bg: #ffffff;
  --card-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  --border-radius: 10px;
  --font-family: 'Roboto', sans-serif;
  --transition-speed: 0.3s;
}

body {
  font-family: var(--font-family);
  background: var(--background-gradient);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Ensure the body takes up the full viewport height */
}

/* Ensure the App container uses the full height and background */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--background-gradient); /* Apply the gradient to the App container as well */
}

.main-header {
  background-color: var(--background-gradient);
  padding: 10px 50px; /* Default untuk desktop */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
}

/* Media query untuk mobile */
@media (max-width: 768px) {
  .main-header {
    padding: 25px 50px; /* Padding untuk mobile */
  }
}


.header-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/* Search Filter in Header */
.search-filter-header {
  width: 100%;
  max-width: 500px;
}

.header-search-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  font-size: 1rem;
  outline: none;
  transition: border-color var(--transition-speed);
}

.header-search-input:focus {
  border-color: var(--primary-color);
}

/* Menu Toggle for Mobile */
.menu-toggle {
  background: none;
  border: none;
  font-size: 1.5em;
  color: var(--primary-color);
  cursor: pointer;
  display: none;
}

/* Desktop Navigation */
.desktop-nav {
  display: flex;
  justify-content: center;
  gap: 15px;
  background-color: var(--background-gradient);
  padding: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px;
}

.nav-button {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-button.active,
.nav-button:hover {
  background-color: var(--primary-color);
  color: #fff;
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 20px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--card-bg);
  border-radius: var(--border-radius);
  padding: 25px;
  max-width: 500px;
  width: 90%;
  box-shadow: var(--card-shadow);
  position: relative;
}

/* Sidebar Styles */
.sidebar {
  display: none;
}




@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    position: absolute; /* Keep absolute positioning */
    top: 50%; /* Center vertically */
    left: 20px; /* Positioned from the left */
    transform: translateY(-50%); /* Perfect vertical centering */
    z-index: 1001; /* Ensure it's above other elements */
  }

  .desktop-nav {
    display: none;
  }

.sidebar {
  display: block;
  position: fixed;
  top: 0;
  left: -100%; /* Sembunyikan sidebar di luar skrin */
  width: 80%;
  height: 100%;
  background-color: var(--card-bg);
  box-shadow: var(--card-shadow);
  transition: left 0.3s ease; /* Transition berdasarkan 'left' */
  z-index: 1000;
}

.sidebar.open {
  left: 0; /* Papar sidebar dengan menggerakkannya ke dalam skrin */
}

.sidebar nav {
  display: flex;
  flex-direction: column;
  padding: 20px;
}


  .close-sidebar {
    background: none;
    border: none;
    font-size: 1.5em;
    color: var(--primary-color);
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: 20px;
  }

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Z-index lebih rendah daripada sidebar tetapi lebih tinggi daripada kandungan lain */
}

.sidebar .nav-button {
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 15px;
  display: flex; /* Pastikan butang kelihatan */
  align-items: center;
  gap: 8px;
  padding: 10px;
  font-size: 1rem;
}
} 